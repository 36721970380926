import { useState } from "react";
import { Col, Row, Form, Container } from "react-bootstrap";
import { StoreItem } from "../../cart/StoreItem";
import storeItems from "../../../data/items.json";

export function Potensmedel() {
  const [searchTerm, setSearchTerm] = useState("");
  const desiredIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21,22, 23, 24, 25, 26, 27, 28, 29, 30, 31,46,47,48,49,50,51,52,53,54,55,56,57];

  const filteredItems = storeItems
    .filter(item => desiredIds.includes(item.id))
    .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <section>
      <Container>
        <Row className="justify-content-center mb-3 pt-3 pb-3">
          <Col xs={12} md={6} lg={4}>
            <Form.Control
              type="text"
              placeholder="Sök produkter..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="text-center"
            />
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3} xl={3} className="product-display g-3">
          {filteredItems.map(item => (
            <Col key={item.id}>
              <StoreItem {...item} showLink={true} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
